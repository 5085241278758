import { render, staticRenderFns } from "./PageError.vue?vue&type=template&id=30d192f9&scoped=true&"
import script from "./PageError.vue?vue&type=script&lang=js&"
export * from "./PageError.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30d192f9",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VBtn,VCard,VCardText,VIcon,VImg})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('30d192f9')) {
      api.createRecord('30d192f9', component.options)
    } else {
      api.reload('30d192f9', component.options)
    }
    module.hot.accept("./PageError.vue?vue&type=template&id=30d192f9&scoped=true&", function () {
      api.rerender('30d192f9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/PageError/PageError.vue"
export default component.exports