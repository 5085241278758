var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "text-center", attrs: { flat: "" } },
    [
      _c(
        "v-card-text",
        [
          _vm.statusCode === 404
            ? _c("v-img", {
                staticClass: "mx-auto",
                attrs: {
                  to: "/",
                  src: require("@/assets/img/PageError/error-404.png"),
                  "max-width": "600"
                }
              })
            : _vm._e(),
          _vm.statusCode === 403
            ? _c("v-img", {
                staticClass: "mx-auto",
                attrs: {
                  to: "/",
                  src: require("@/assets/img/PageError/error-403.png"),
                  "max-width": "600"
                }
              })
            : _vm._e(),
          _vm.statusCode === 403
            ? _c(
                "v-btn",
                { attrs: { to: "/", color: "primary", depressed: "" } },
                [
                  _c("v-icon", { attrs: { left: "", small: "" } }, [
                    _vm._v(" mdi-home ")
                  ]),
                  _vm._v(" GO TO HOMEPAGE ")
                ],
                1
              )
            : _vm._e(),
          _vm.statusCode === 403
            ? _c(
                "v-btn",
                {
                  attrs: {
                    href: "https://www." + this.domainName + "/whalebox",
                    color: "primary",
                    depressed: ""
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "", small: "" } }, [
                    _vm._v(" mdi-login ")
                  ]),
                  _vm._v(" GO TO LOGIN ")
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }