<template>
  <v-card
    class="text-center"
    flat>
    <v-card-text>
      <v-img
        v-if="statusCode === 404"
        to="/"
        src="@/assets/img/PageError/error-404.png"
        max-width="600"
        class="mx-auto">
      </v-img>
      <v-img
        v-if="statusCode === 403"
        to="/"
        src="@/assets/img/PageError/error-403.png"
        max-width="600"
        class="mx-auto">
      </v-img>
      <!-- <v-list-item
        v-else-if="statusCode === 403"
        three-line>
        <v-list-item-content>
          <div class="display-4 mb-6">
            Oops!
          </div>
          <v-list-item-title class="headline mb-1">
            {{ message.statusCode }} - {{ message.title }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <pre>{{ message.description }}</pre>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item> -->
      <v-btn
        v-if="statusCode === 403"
        to="/"
        color="primary"
        depressed>
        <v-icon
          left
          small>
          mdi-home
        </v-icon>
        GO TO HOMEPAGE
      </v-btn>
      <v-btn
        v-if="statusCode === 403"
        :href="`https://www.${this.domainName}/whalebox`"
        color="primary"
        depressed>
        <v-icon
          left
          small>
          mdi-login
        </v-icon>
        GO TO LOGIN
      </v-btn>
    </v-card-text>
    <!-- <v-card-actions class="justify-center">
      <v-btn
        v-if="statusCode === 404"
        to="/"
        color="primary"
        depressed>
        <v-icon
          left
          small>
          mdi-home
        </v-icon>
        GO TO HOMEPAGE
      </v-btn>
      <v-btn
        v-else-if="statusCode === 403"
        href="https://www.whalebox.co/"
        color="primary"
        depressed>
        <v-icon
          left
          small>
          mdi-login
        </v-icon>
        GO TO LOGIN
      </v-btn>
    </v-card-actions> -->
  </v-card>
</template>

<script>
export default {
  name: 'PageError',
  props: {
    statusCode: {
      type: Number,
      default: () => 404
    }
  },
  data () {
    return {
      statusMessage: {
        404: {
          statusCode: 404,
          title: 'PAGE NOT FOUND',
          description: 'This page you looking for might have been removed\r\nhad its name changed or is temporarily unavailable.'
        },
        403: {
          statusCode: 403,
          title: 'ACCESS DENIED',
          description: 'You do not have permission to access message system.'
        }
      }
    }
  },
  computed: {
    domainName () {
      const host = window.location.hostname
      const parts = host.split('.')
      if (parts.length === 3) {
        return `${parts[1]}.${parts[2]}`
      } if (parts.length === 2) {
        return `${parts[0]}.${parts[1]}`
      }
      return parts[0]
    },
    message () {
      return this.statusMessage[this.statusCode] || this.statusMessage[404]
    }
  }
}
</script>

<style>
</style>

<style scoped>
</style>
